import _ from 'lodash';
import { Api } from '@/api';

const state = {
  models: [],
};

const getters = {
  enableParcelModels: s => _.filter(s.models, { status: true }),
};

const actions = {
  saveParcelModel({ commit }, params) {
    return Api.parcel.modelSave(params).then(({ data: response }) => {
      if (params.id) {
        commit('updateParcel', response.data);
      } else {
        commit('addParcelModel', response.data);
      }
    });
  },
  fetchParcelModels({ commit }) {
    return Api.parcel.models().then(({ data: response }) => {
      commit('receivePercelModels', response.data);
    });
  },
};

/* eslint no-param-reassign: 0, no-shadow: 0 */
const mutations = {
  receivePercelModels(s, data) {
    s.models = [...data];
  },
  addParcelModel(s, data) {
    s.models.push(data);
  },
  updateParcel(s, data) {
    const index = _.findIndex(s.models, { id: data.id });
    s.models[index] = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
