const Menu = [
  {
    name: 'Dashboard',
    icon: 'fas fa-tachometer-alt',
    path: '/dashboard',
  },
  // {
  //   name: '設置',
  //   icon: 'fas fa-cog',
  //   path: '/config',
  // },
  {
    name: '管理员',
    icon: 'fas fa-user-friends',
    all_privileges: true,
    submenu: [
      {
        name: '添加管理员',
        path: '/admin',
      },
      {
        name: '管理员列表',
        path: '/admin-list',
      },
    ],
  },
  {
    name: '門店管理',
    icon: 'fas fa-warehouse',
    all_privileges: true,
    submenu: [
      {
        name: '添加門店',
        path: '/post-office',
      },
      {
        name: '門店列表',
        path: '/post-office-list',
      },
    ],
  },
  {
    name: '用戶列表',
    icon: 'fas fa-users',
    all_privileges: true,
    path: '/users',
  },
  {
    name: '郵包管理',
    icon: 'fas fa-box',
    all_privileges: true,
    submenu: [
      {
        name: '郵包模型',
        path: '/parcel-models',
      },
      {
        name: '郵包列表',
        path: '/parcels',
      },
      {
        name: '創建郵包號',
        path: '/create-parcels',
      },
      {
        name: '導入郵包號',
        path: '/import-parcels',
      },
      {
        name: '綁定門店',
        path: '/bind-parcels',
      },
    ],
  },
  {
    name: '取件訂單管理',
    icon: 'fas fa-list',
    all_privileges: true,
    submenu: [
      // {
      //   name: '所有訂單',
      //   path: '/pickup-orders/all',
      // },
      {
        name: '未支付訂單',
        path: '/pickup-orders/unpaid',
      },
      {
        name: '已支付訂單',
        path: '/pickup-orders/paid',
      },
      {
        name: '取件中訂單',
        path: '/pickup-orders/picking',
      },
      {
        name: '已取件訂單',
        path: '/pickup-orders/picked',
      },
      {
        name: '已取消訂單',
        path: '/pickup-orders/close',
      },
    ],
  },
  {
    name: '訂單攬件',
    icon: 'fas fa-weight-hanging',
    path: '/order-prick-up',
  },
  {
    name: '稱重打單',
    icon: 'fas fa-weight-hanging',
    path: '/weighting-print-label',
    all_privileges: true,
  },
  {
    name: '訂單拆分',
    icon: 'fas fa-sitemap',
    path: '/order-split',
    all_privileges: true,
  },
  {
    name: '訂單管理',
    icon: 'fas fa-list',
    all_privileges: true,
    submenu: [
      {
        name: '所有訂單',
        path: '/orders/all',
      },
      {
        name: '未支付訂單',
        path: '/orders/unpaid',
      },
      {
        name: '已支付訂單',
        path: '/orders/paid',
      },
      {
        name: '已攬件訂單',
        path: '/orders/pickup',
      },
      {
        name: '未打印訂單',
        path: '/orders/unprinted',
      },
      {
        name: '已打印訂單',
        path: '/orders/printed',
      },
      {
        name: '已完成訂單',
        path: '/orders/completed',
      },
      {
        name: '已關閉訂單',
        path: '/orders/closed',
      },
      // {
      //   name: '已取消訂單',
      //   path: '/orders/canceled',
      // },
      {
        name: '問題訂單',
        path: '/orders/issue',
      },
      {
        name: '退件訂單',
        path: '/orders/return',
      },
    ],
  },
  {
    name: '優惠碼管理',
    icon: 'fas fa-list',
    all_privileges: true,
    submenu: [
      {
        name: '創建優惠碼',
        path: '/coupon-create',
      },
      {
        name: '優惠碼列表',
        path: '/coupons',
      },
    ],
  },
  {
    name: '地區管理',
    icon: 'fas fa-map-marker',
    all_privileges: true,
    submenu: [
      {
        name: '地區列表',
        path: '/districts',
      },
      {
        name: '區域群組列表',
        path: '/group',
      },
    ],
  },
  {
    name: '系統消息管理',
    icon: 'fas fa-tasks',
    path: '/system-information',
    all_privileges: true,
  },
  {
    name: '運貨條款',
    icon: 'fas fa-tasks',
    path: '/delivery-terms',
    all_privileges: true,
  },
  {
    name: '隱私政策',
    icon: 'fas fa-tasks',
    path: '/privacy-policy',
    all_privileges: true,
  },
  {
    name: '信用卡條款',
    icon: 'fas fa-tasks',
    path: '/credit-card-terms',
    all_privileges: true,
  },
  {
    name: '用戶協議',
    icon: 'fas fa-tasks',
    path: '/user-agreement',
    all_privileges: true,
  },
  {
    name: '申請加盟列表',
    icon: 'fas fa-tasks',
    path: '/join-us',
    all_privileges: true,
  },
  // {
  //   heading: 'Main Navigation',
  // },
  // {
  //   name: 'Single View',
  //   path: 'singleview',
  //   icon: 'icon-grid',
  // },
  // {
  //   name: 'Menu',
  //   icon: 'icon-speedometer',
  //   label: {
  //     value: 1,
  //     color: 'info',
  //   },
  //   submenu: [{
  //     name: 'Submenu',
  //     path: 'submenu',
  //   }],
  // },
];

export default Menu;
