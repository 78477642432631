import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import createPersistedState from 'vuex-persistedstate';

import SettingModule from './modules/setting';
import SettingPlugin from './plugins/setting';
import ThemeModule from './modules/theme';
import ThemePlugin from './plugins/theme';

import user from './modules/user';
import admin from './modules/admin';
import postOffice from './modules/postOffice';
import parcel from './modules/parcel';
import order from './modules/order';
import pickupOrder from './modules/pickupOrder';
import coupon from './modules/coupon';
import information from './modules/information';

import appConfig from '../app.config';
import { encrypt, decrypt } from '../helpers';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    setting: SettingModule,
    theme: ThemeModule,
    user,
    admin,
    postOffice,
    parcel,
    order,
    coupon,
    information,
    pickupOrder,
  },
  plugins: [
    createPersistedState({
      key: appConfig.VUEX_STORE_KEY,
      storage: appConfig.VUEX_STORE_STORAGE,
      reducer: (persistedState) => {
        const stateFilter = _.cloneDeep(persistedState);
        [
          'setting.offsidebarOpen', 'setting.asideToggled', 'setting.horizontal',
          'order.orderTabs', 'pickupOrder.orderTabs',
          'order.statuses', 'pickupOrder.statuses', 'postOffice.types',
        ].forEach(key => _.unset(stateFilter, key));
        return stateFilter;
      },
      getState(key, storage) {
        const data = storage.getItem(key);
        if (data) {
          try {
            return decrypt(data);
          } catch (e) {
            return null;
          }
        }
        return null;
      },
      setState(key, data, storage) {
        const encrypted = encrypt(data);
        storage.setItem(key, encrypted);
      },
    }),
    SettingPlugin,
    ThemePlugin,
  ],
});
