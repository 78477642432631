import _ from 'lodash';
import { Api } from '@/api';

const state = {
  coupons: [],
};

const getters = {};

const actions = {
  fetchCoupons({ commit }) {
    return Api.coupon.get().then(({ data: response }) => {
      commit('receiveCoupons', response.data);
    });
  },
  saveCoupon({ commit }, params) {
    return Api.coupon.post(params).then((response) => {
      if (params.code) {
        commit('updateCoupon', params);
      } else {
        commit('receiveNewCoupon', response.data);
      }
    });
  },
  removeCoupon({ commit }, code) {
    return Api.coupon.delete(code).then(() => {
      commit('deleteCoupon', code);
    });
  },
};

/* eslint no-param-reassign: 0, no-shadow: 0 */
const mutations = {
  receiveCoupons(s, data) {
    s.coupons = [...data];
  },
  receiveNewCoupon(s, data) {
    s.coupons = [...s.coupons, data];
  },
  updateCoupon(s, data) {
    const coupon = _.find(s.coupons, { code: data.code });
    _.merge(coupon, data);
  },
  deleteCoupon(s, code) {
    s.coupons = _.reject(s.coupons, { code });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
