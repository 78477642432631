import _ from 'lodash';

const state = {
  statuses: [
    {
      label: '未支付',
      value: 0,
    },
    {
      label: '已支付',
      value: 1,
    },
    {
      label: '取件中',
      value: 2,
    },
    {
      label: '已取件',
      value: 3,
    },
    {
      label: '已取消',
      value: 9,
    },
  ],
  orderTabs: [],
};

const getters = {
  pickupOrderStatusesObj: s => _.keyBy(s.statuses, 'value'),
  pickupOrderTabsObj: s => _.keyBy(s.orderTabs, 'name'),
};

const actions = {
};

/* eslint no-param-reassign: 0, no-shadow: 0 */
const mutations = {
  addPickupOrderTab(s, data) {
    s.orderTabs.push(data);
  },
  removePickupOrderTab(s, tabName) {
    s.orderTabs = _.reject(s.orderTabs, tab => tab.name === tabName);
  },
  setPickupOrderTabUpdate(s, { name, update = false }) {
    const tab = _.find(s.orderTabs, { name });
    if (tab) {
      tab.update = update;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
