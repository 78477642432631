import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import BootstrapVue from 'bootstrap-vue';
import ElementUI from 'element-ui';
import { ClientTable, ServerTable } from 'vue-tables-2';
import md5 from 'js-md5';
import locale from 'element-ui/lib/locale/lang/zh-TW';
import VueQuillEditor from 'vue-quill-editor';

import App from './App';
import router from './router';
import store from './store';
import ApiPlugin from './api';
import AppConfig from './app.config';
import './filters';
import 'quill/dist/quill.snow.css';

import './modernizr.custom';

const {
  API_STANDARDS_TREE,
  APP_NAME,
  API_VERSION,
  SECRET_KEY,
} = AppConfig;

Vue.use(VueQuillEditor);
Vue.use(ApiPlugin);
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(ElementUI, { locale });
Vue.use(ClientTable, {
  sortIcon: {
    base: 'fas',
    up: 'fa-sort-up',
    down: 'fa-sort-down',
    is: 'fa-sort',
  },
}, false, 'bootstrap4', 'default');
Vue.use(ServerTable, {
  sortIcon: {
    base: 'fas',
    up: 'fa-sort-up',
    down: 'fa-sort-down',
    is: 'fa-sort',
  },
}, false, 'bootstrap4', 'default');

Vue.config.productionTip = process.env.NODE_ENV === 'development';

new Vue({
  router,
  store,
  render: h => h(App),
  watch: {
    '$store.state.user.token': {
      handler(val) {
        if (val) {
          this.$store.dispatch('getIdentity');
        }
      },
      immediate: true,
    },
  },
  beforeCreate() {
    // axios Request
    this.axios.defaults.headers.common.Accept = `application/${API_STANDARDS_TREE}.${APP_NAME}.${API_VERSION}+json`;
    this.axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
    this.axios.interceptors.request.use((config) => {
      /* eslint no-param-reassign: 0 */
      if (this.$store.getters.isLogined) {
        const { token, expiresTime } = this.$store.state.user;
        // 检查token是否过期
        if (expiresTime > Date.now()) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          this.$store.dispatch('logout');
        }
      }
      const ts = Date.now();
      config.headers.Signature = md5([window.location.hostname, navigator.userAgent, ts, SECRET_KEY].join('|'));
      config.headers.ts = ts;
      return config;
    });
    // axios Response
    this.axios.interceptors.response.use((response) => {
      if (response.headers['x-track-id'] && response.headers['x-track-id'] !== this.$store.state.app.trackID) {
        this.$store.dispatch('setTrackId', response.headers['x-track-id']);
      }
      return response;
    }, (error) => {
      if (error.response) {
        /* eslint default-case:0 */
        switch (error.response.status) {
          case 401:
            return this.$store.dispatch('logout').then(() => {
              this.$router.replace({ name: 'login' });
            });
          case 500:
            //
            break;
        }
      }
      return Promise.reject(error);
    });
  },
}).$mount('#app');
