import _ from 'lodash';
import { Api } from '@/api';

const state = {
  types: [
    {
      label: '香港郵政局',
      value: 1,
    },
    {
      label: '香港自提點',
      value: 2,
    },
    {
      label: '香港自提柜',
      value: 3,
    },
    {
      label: '智郵站',
      value: 4,
    },
    {
      label: '自營自提點',
      value: 5,
    },
    {
      label: '智能櫃',
      value: 6,
    },
    {
      label: '加盟商',
      value: 7,
    },
  ],
  postOfficeList: [],
};

const getters = {
  typesObj: s => _.keyBy(s.types, 'value'),
};

const actions = {
  fetchOffice({ commit }) {
    return Api.office.get().then(({ data: response }) => {
      commit('getOffices', response);
    });
  },
  deleteOffice({ commit }, id) {
    return Api.office.remove(id).then(() => {
      commit('removeOffice', id);
    });
  },
};

/* eslint no-param-reassign: 0, no-shadow: 0 */
const mutations = {
  getOffices(s, res) {
    s.postOfficeList = res.data;
  },
  removeOffice(s, id) {
    s.postOfficeList = _.reject(s.postOfficeList, { id });
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
