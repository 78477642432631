import Vue from 'vue';
import appConfig from './app.config';

const { API_HOST } = appConfig;

const Api = {
  statistics: {
    realTime() {
      return Vue.axios.get(`${API_HOST}/real-time-statistics`);
    },
    daily(params = {}) {
      return Vue.axios.get(`${API_HOST}/daily-statistics`, { params });
    },
  },
  user: {
    authenticate(params) {
      return Vue.axios.post(`${API_HOST}/authenticate`, params);
    },
    identity() {
      return Vue.axios.get(`${API_HOST}/identity`);
    },
    refresh() {
      return Vue.axios.post(`${API_HOST}/refresh`);
    },
    get() {
      return Vue.axios.get(`${API_HOST}/user`);
    },
  },
  admin: {
    // 获取 单个/所有 列表
    get(id = null) {
      return Vue.axios.get(id ? `${API_HOST}/admin/${id}` : `${API_HOST}/admin`);
    },
    // 添加/修改
    save(id) {
      return Vue.axios.post(`${API_HOST}/admin`, id);
    },
    // 删除
    remove(id) {
      return Vue.axios.delete(`${API_HOST}/admin/${id}`);
    },
  },
  district: {
    // 获取地区列表
    tree() {
      return Vue.axios.get(`${API_HOST}/district`);
    },
    root(params = {}) {
      return Vue.axios.get(`${API_HOST}/district-root`, { params });
    },
    update(id, params) {
      return Vue.axios.put(`${API_HOST}/district/${id}`, params);
    },
  },
  group: {
    get(id = null) {
      return Vue.axios.get(id ? `${API_HOST}/group/${id}` : `${API_HOST}/group`);
    },
    save(params) {
      return Vue.axios.post(`${API_HOST}/group`, params);
    },
    // 删除
    remove(id) {
      return Vue.axios.delete(`${API_HOST}/group/${id}`);
    },
  },
  config: {
    get(id = null) {
      return Vue.axios.get(id ? `${API_HOST}/config/${id}` : `${API_HOST}/config`);
    },
    save(params) {
      return Vue.axios.post(`${API_HOST}/config/`, params);
    },
  },
  office: {
    // 编辑邮局
    save(params) {
      return Vue.axios.post(`${API_HOST}/post-office`, params);
    },
    updateStatus(params) {
      return Vue.axios.post(`${API_HOST}/post-office-status`, params);
    },
    // 获取邮局
    getById(id) {
      return Vue.axios.get(`${API_HOST}/post-office/${id}`);
    },
    get(params) {
      return Vue.axios.get(`${API_HOST}/post-office`, { params });
    },
    // 删除
    remove(id) {
      return Vue.axios.delete(`${API_HOST}/post-office/${id}`);
    },
    qrcode(id) {
      return Vue.axios.get(`${API_HOST}/post-office-qrcode/${id}`);
    },
  },
  parcel: {
    models() {
      return Vue.axios.get(`${API_HOST}/parcel-model`);
    },
    modelSave(params) {
      return Vue.axios.post(`${API_HOST}/parcel-model`, params);
    },
    save(params) {
      return Vue.axios.post(`${API_HOST}/parcel`, params);
    },
    parcels(params) {
      return Vue.axios.get(`${API_HOST}/parcel`, { params });
    },
    bind(code, post_office_id) {
      return Vue.axios.put(`${API_HOST}/parcel/${code}`, { post_office_id });
    },
    batchUnbind(params) {
      return Vue.axios.post(`${API_HOST}/batch-unbind`, params);
    },
    batchPrint(params) {
      return Vue.axios.post(`${API_HOST}/invoice-batch`, params);
    },
    removeParcel(id) {
      return Vue.axios.delete(`${API_HOST}/parcel/${id}`);
    },
    exportData(params) {
      return Vue.axios.post(`${API_HOST}/parcel-export`, { params });
    },
  },
  pickupOrder: {
    get(params = {}) {
      return Vue.axios.get(`${API_HOST}/pickup-order`, { params });
    },
    getById(id) {
      return Vue.axios.get(`${API_HOST}/pickup-order/${id}`);
    },
    update(id, params) {
      return Vue.axios.put(`${API_HOST}/pickup-order/${id}`, params);
    },
    statusLogs(id) {
      return Vue.axios.get(`${API_HOST}/pickup-order-status-logs/${id}`);
    },
    close(id) {
      return Vue.axios.delete(`${API_HOST}/pickup-order/${id}`);
    },
    forceClose(id) {
      return Vue.axios.delete(`${API_HOST}/pickup-order-force-close/${id}`);
    },
  },
  order: {
    get(params = {}) {
      return Vue.axios.get(`${API_HOST}/order`, { params });
    },
    getById(id) {
      return Vue.axios.get(`${API_HOST}/order/${id}`);
    },
    update(id, params) {
      return Vue.axios.put(`${API_HOST}/order/${id}`, params);
    },
    getByParcelCode(code) {
      return Vue.axios.get(`${API_HOST}/order/${code}`, { params: { type: 'parcel_code' } });
    },
    close(id) {
      return Vue.axios.delete(`${API_HOST}/order/${id}`);
    },
    forceClose(id) {
      return Vue.axios.delete(`${API_HOST}/order-force-close/${id}`);
    },
    tracking(id) {
      return Vue.axios.get(`${API_HOST}/order-tracking/${id}`);
    },
    changeIssue(params) {
      return Vue.axios.post(`${API_HOST}/order-issue`, params);
    },
    issueList(params) {
      return Vue.axios.get(`${API_HOST}/order-issue-list`, params);
    },
    statusLogs(id) {
      return Vue.axios.get(`${API_HOST}/order-status-logs/${id}`);
    },
    saveItem(params) {
      return Vue.axios.post(`${API_HOST}/save-order-item`, params);
    },
    deleteItem(id) {
      return Vue.axios.delete(`${API_HOST}/delete-order-item/${id}`);
    },
    createSubOrder(params) {
      return Vue.axios.post(`${API_HOST}/sub-order`, params);
    },
    pickup(id) {
      return Vue.axios.get(`${API_HOST}/order-pick-up/${id}`);
    },
    return(params) {
      return Vue.axios.post(`${API_HOST}/order-return`, params);
    },
    complete(id) {
      return Vue.axios.get(`${API_HOST}/order-complete/${id}`);
    },
    recalculatePrice(id) {
      return Vue.axios.put(`${API_HOST}/recalculate-price/${id}`);
    },
  },
  orderAdditionalFee: {
    save(params) {
      return Vue.axios.post(`${API_HOST}/order-additional-fee`, params);
    },
    cancel(id) {
      return Vue.axios.delete(`${API_HOST}/order-additional-fee/${id}`);
    },
  },
  coupon: {
    get() {
      return Vue.axios.get(`${API_HOST}/coupon`);
    },
    getById(id) {
      return Vue.axios.get(`${API_HOST}/coupon/${id}`);
    },
    post(params) {
      return Vue.axios.post(`${API_HOST}/coupon`, params);
    },
    delete(id) {
      return Vue.axios.delete(`${API_HOST}/coupon/${id}`);
    },
    getImg(id) {
      return Vue.axios.get(`${API_HOST}/coupon-img/${id}`);
    },
  },
  productCategroy: {
    get() {
      return Vue.axios.get(`${API_HOST}/product-categories`);
    },
  },
  information: {
    getDeliveryTerms() {
      return Vue.axios.get(`${API_HOST}/delivery-terms`);
    },
    saveDeliveryTerms(params) {
      return Vue.axios.post(`${API_HOST}/delivery-terms`, params);
    },
    getPrivacyPolicy() {
      return Vue.axios.get(`${API_HOST}/privacy-policy`);
    },
    savePrivacyPolicy(params) {
      return Vue.axios.post(`${API_HOST}/privacy-policy`, params);
    },
    getCreditCardTerms() {
      return Vue.axios.get(`${API_HOST}/credit-card-terms`);
    },
    saveCreditCardTerms(params) {
      return Vue.axios.post(`${API_HOST}/credit-card-terms`, params);
    },
    getUserAgreement() {
      return Vue.axios.get(`${API_HOST}/user-agreement`);
    },
    saveUserAgreement(params) {
      return Vue.axios.post(`${API_HOST}/user-agreement`, params);
    },
    get() {
      return Vue.axios.get(`${API_HOST}/system-information`);
    },
    getById(id) {
      return Vue.axios.get(`${API_HOST}/system-information/${id}`);
    },
    post(params) {
      return Vue.axios.post(`${API_HOST}/system-information`, params);
    },
    put(id, params) {
      return Vue.axios.put(`${API_HOST}/system-information/${id}`, params);
    },
    delete(id) {
      return Vue.axios.delete(`${API_HOST}/system-information/${id}`);
    },
  },
  joinUs: {
    get() {
      return Vue.axios.get(`${API_HOST}/join-us`);
    },
  },
};

export default function plugin(_Vue) {
  if (plugin.installed) {
    return;
  }
  plugin.installed = true;

  Object.defineProperty(_Vue.prototype, '$api', {
    get() {
      return Api;
    },
  });
}

export {
  Api,
};
