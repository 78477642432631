import _ from 'lodash';
import { Api } from '@/api';

const state = {
  statuses: [
    {
      label: '未支付',
      value: 1,
    },
    {
      label: '已支付',
      value: 2,
    },
    {
      label: '已攬件',
      value: 3,
    },
    {
      label: '運輸中',
      value: 4,
    },
    {
      label: '管理員關閉',
      value: 11,
    },
    {
      label: '完成',
      value: 5,
    },
    {
      label: '用戶取消',
      value: 12,
    },
    {
      label: '超時取消',
      value: 13,
    },
    {
      label: '港前退件',
      value: 18,
    },
    {
      label: '港後退件',
      value: 19,
    },
  ],
  orderTabs: [],
  productCategories: [],
};

const getters = {
  orderStatusesObj: s => _.keyBy(s.statuses, 'value'),
  orderTabsObj: s => _.keyBy(s.orderTabs, 'name'),
};

const actions = {
  fetchProductCategories({ commit }) {
    return Api.productCategroy.get().then(({ data: response }) => {
      commit('receiveProductCategories', response);
    });
  },
};

/* eslint no-param-reassign: 0, no-shadow: 0 */
const mutations = {
  addOrderTab(s, data) {
    s.orderTabs.push(data);
  },
  removeOrderTab(s, tabName) {
    s.orderTabs = _.reject(s.orderTabs, tab => tab.name === tabName);
  },
  setOrderTabUpdate(s, { name, update = false }) {
    const tab = _.find(s.orderTabs, { name });
    if (tab) {
      tab.update = update;
    }
  },
  receiveProductCategories(s, data) {
    s.productCategories = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
