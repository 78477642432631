import _ from 'lodash';
import { Api } from '@/api';

const state = {
  adminList: [],
};
const getters = {};
const actions = {
  fetchAdmin({ commit }) {
    return Api.admin.get().then(({ data: response }) => {
      commit('receiveAdminList', response.data);
    });
  },
  deleteAdmin({ commit }, id) {
    return Api.admin.remove(id).then(() => {
      commit('removeAdmin', id);
    });
  },
};

/* eslint no-param-reassign: 0, no-shadow: 0 */
const mutations = {
  receiveAdminList(s, data) {
    s.adminList = data;
  },
  removeAdmin(s, id) {
    s.adminList = _.reject(s.adminList, { id });
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
