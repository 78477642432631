<template>
  <aside class="aside-container">
    <!-- START Sidebar (left)-->
    <div class="aside-inner">
      <nav class="sidebar" data-sidebar-anyclick-close="" v-if="identity">
        <!-- START sidebar nav-->
        <ul class="sidebar-nav">
          <!-- START user info-->
          <li class="has-user-block">
            <b-collapse :visible="showUserBlock" id="user-block">
              <div class="item user-block">
                <!-- User picture-->
                <div class="user-block-picture">
                  <div class="user-block-status">
                    <el-avatar>{{ currentUser.account }}</el-avatar>
                  </div>
                </div>
                <!-- Name and Job-->
                <div class="user-block-info">
                  <span class="user-block-name">Hello, {{ currentUser.account }}</span>
                  <span class="user-block-role">Designer</span>
                  <div class="text-center">
                    <a href="javascript: void(0)" @click="logout">登出</a>
                  </div>
                </div>
              </div>
            </b-collapse>
          </li>
          <!-- END user info-->
          <!-- Iterates over all sidebar items-->
          <template v-for="item in Menu" >
            <!-- Heading -->
            <li class="nav-heading" v-if="item.heading" :key="item.heading">
              <span>{{ item.heading }}</span>
            </li>
            <!-- Single Menu -->
            <router-link tag="li" :key="item.path" :to="item.path" v-if="!item.heading && !item.submenu
            && (item.all_privileges ? identity.all_privileges : true)" >
              <a :title="item.name">
                <span v-if="item.label"
                      :class="'float-right badge badge-'+item.label.color">{{ item.label.value }}</span>
                <em :class="item.icon"></em>
                <span>{{ item.name }}</span>
              </a>
            </router-link>
            <!-- Menu With Subitems -->
            <li :class="routeActiveClass(getSubRoutes(item))" v-bind:key="item.value" v-if="!item.heading && item.submenu &&
             (item.all_privileges ? identity.all_privileges : false)">
              <a :title="item.name" @click.prevent="toggleItemCollapse(item.name)" href>
                <span v-if="item.label" :class="'float-right badge badge-'+item.label.color">{{item.label.value}}</span>
                <em :class="item.icon"></em>
                <span>{{ item.name }}</span>
              </a>
              <b-collapse tag="ul" class="sidebar-nav sidebar-subnav" id="item.name" v-model="collapse[item.name]">
            <li class="sidebar-subnav-header">{{ item.name }}</li>
            <template v-for="sitem in item.submenu">
              <router-link tag="li" :to="sitem.path" active-class="active" v-bind:key="sitem.value"
                           v-if="sitem.all_privileges ? identity.all_privileges : true">
                <a :title="sitem.name">
                  <span v-if="sitem.label"
                        :class="'float-right badge badge-'+sitem.label.color">{{ sitem.label.value }}</span>
                  <span>{{ sitem.name }}
                                                </span>
                </a>
              </router-link>
            </template>
            </b-collapse>
            </li>
          </template>
        </ul>
        <!-- END sidebar nav-->
      </nav>
    </div>
    <!-- END Sidebar (left)-->
  </aside>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import SidebarRun from './Sidebar.run';
import Menu from '../../menu';

export default {
  name: 'Sidebar',
  data() {
    return {
      Menu,
      collapse: this.buildCollapseList(),
    };
  },
  mounted() {
    SidebarRun(this.$router);
  },
  computed: {
    ...mapState({
      showUserBlock: state => state.setting.showUserBlock,
      identity: state => state.user.identity,
    }),
    ...mapGetters([
      'currentUser',
      'hasPermission',
      'hasRole',
    ]),
  },
  watch: {
    $route() {
      this.$store.commit('changeSetting', {
        name: 'asideToggled',
        value: false,
      });
    },
  },
  methods: {
    buildCollapseList() {
      /** prepare initial state of collapse menus. Doesnt allow same route names */
      const collapse = {};
      Menu
        .filter(({ heading }) => !heading)
        .forEach(({ name, path, submenu }) => {
          collapse[name] = this.isRouteActive(submenu ? _.map(submenu, 'path') : path);
        });
      return collapse;
    },
    getSubRoutes(item) {
      return item.submenu.map(({ path }) => path);
    },
    isRouteActive(paths) {
      // eslint-disable-next-line
      paths = Array.isArray(paths) ? paths : [paths];
      return paths.some(p => p === this.$route.path);
    },
    routeActiveClass(paths) {
      return { active: this.isRouteActive(paths) };
    },
    toggleItemCollapse(collapseName) {
      _.forEach(this.collapse, (c) => {
        if (this.collapse[c] === true && c !== collapseName) {
          this.collapse[c] = false;
        }
      });
      this.collapse[collapseName] = !this.collapse[collapseName];
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        window.location.href = '/login';
      });
    },
  },
};
</script>
