import { Notification, Loading } from 'element-ui';
import _ from 'lodash';
import accounting from 'accounting';
import xxtea from 'xxtea-node';
import appConfig from './app.config';

/**
 * 使用element-ui通知组件显示api相应的errors信息
 * @param error
 */
export function showErrorMessage(error) {
  if (error.response) {
    const { errors, message } = error.response.data;
    if (errors) {
      _.forEach(errors, (msgs) => {
        _.forEach(msgs, (msg) => {
          setTimeout(() => {
            Notification({
              title: error.response.data.message,
              type: 'error',
              message: msg,
            });
          }, 0);
        });
      });
    } else if (message) {
      setTimeout(() => {
        Notification({
          title: error.response.data.message,
          type: 'error',
        });
      }, 0);
    }
  }
}

export function getLoadingInstance(options = {}) {
  return Loading.service(_.merge({
    text: 'Loading',
    lock: true,
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  }, options));
}

export function Probability(...$args) {
  const probas = [];
  const functions = [];
  const args = _.isArray($args[0]) ? $args[0] : $args;

  args.push({
    p: 0,
    f() {},
  });

  for (let i = 0, l = args.length, sum = 0; i < l; i++) {
    let p = Math.abs(parseFloat(args[i].p));
    const { f } = args[i];

    if (_.isNaN(p) || typeof f !== 'function') {
      throw new TypeError(`Probability.js: Invalid probability object in argument ${i} .`);
    }

    if (/%/.test(args[i].p)) {
      p /= 100.0;
    }

    sum += p;

    if (sum > 1.0) {
      throw new TypeError(`Probability.js: Probability exceeds "1.0"
        (=100%) in argument ${i}: p="${p}" (=${p * 100}%), sum="${sum}" (=${sum * 100}%).`);
    }

    probas[i] = sum;
    functions[i] = f;
  }

  return function probabilitilized() {
    const random = Math.random();
    let i = 0;
    for (let l = probas.length - 1; i < l && random >= probas[i]; i++) {
      /* intentionally left empty */
    }
    return functions[i].apply(this, ...args);
  };
}

export function formatMoney(number, currency) {
  let symbol;
  let precision = 2;
  let format = '%s%v';
  if (typeof currency === 'string') {
    // eslint-disable-next-line
    currency = currency.toUpperCase();
  }
  switch (currency) {
    case 'EUR':
      symbol = '€';
      break;
    case 'GBP':
      symbol = '£';
      break;
    case 'JPY':
      symbol = '¥';
      precision = 0;
      format = '%s%v円';
      break;
    case 'CNY':
    case 'RMB':
      symbol = 'RMB¥';
      break;
    case 'HKD':
      symbol = 'HK$';
      break;
    case 'USD':
      symbol = 'US$';
      break;
    default:
      symbol = currency;
      break;
  }
  return accounting.formatMoney(number, symbol, precision, ',', '.', format);
}

export function encrypt(data) {
  const s = _.isObject(data) ? JSON.stringify(data) : data;
  return xxtea.encryptToString(s, appConfig.SECRET_KEY);
}

export function decrypt(data) {
  const decrypted = xxtea.decryptToString(data, appConfig.SECRET_KEY);
  try {
    return JSON.parse(decrypted);
  } catch (e) {
    return decrypted;
  }
}
