import _ from 'lodash';
import { Api } from '../../api';
/* eslint-disable */
const state = {
  token: '',
  identity: null,
  expiresTime: null,
};
function hasRole(identity, name, requireAll = false) {
  if (_.isArray(name)) {
    for (const roleName of name) {
      const has = hasRole(identity, roleName);
      if (has && !requireAll) {
        return true;
      } else if (!has && requireAll) {
        return false;
      }
    }
    return requireAll;
  }
  if (identity) {
    for (const role of identity.roles) {
      if (role.name === name) {
        return true;
      }
    }
  }
  return false;
}

function hasPermission(identity, name, requireAll = false) {
  if (identity.all_privileges) {
    return true;
  }
  if (_.isArray(name)) {
    for (let permName of name) {
      const has = hasPermission(identity, permName);
      if (has && !requireAll) {
        return true;
      } else if (!has && requireAll) {
        return false;
      }
    }
    return requireAll;
  }
  for (let perm of identity.permissions) {
    if (name === perm) {
      return true;
    }
  }
  return false;
}

const getters = {
  isLogined: s => s.token !== '' || s.identity !== null,
  currentUser: s => s.identity,
  hasRole: s => (name, requireAll = false) => hasRole(s.identity, name, requireAll),
  hasPermission: s => (name, requireAll = false) => hasPermission(s.identity, name, requireAll),
};

const actions = {
  authenticate({ commit, dispatch }, params) {
    return Api.user.authenticate(params)
      .then(({ data: response }) => {
        commit('receiveUserToken', response);
        return dispatch('getIdentity');
      });
  },
  afterLogin() {
    // todo
  },
  getIdentity({ commit, state: s, dispatch }) {
    return Api.user.identity(s.token)
      .then(({ data: response }) => {
        commit('reveiveIdentity', response.data);
        dispatch('afterLogin');
      }).catch((error) => {
        dispatch('logout');
        throw error;
      });
  },
  logout({ commit }) {
    commit('removeToken');
    commit('reveiveIdentity', null);
    commit('setExpireTime', null);
  },
};

/* eslint no-param-reassign: 0, no-shadow: 0 */
const mutations = {
  receiveUserToken(s, response) {
    const expiresTime = Date.now() + (response.expires_in * 1000);
    s.token = response.access_token;
    s.expiresTime = expiresTime;
  },
  setToken(s, token) {
    s.token = token;
  },
  setExpireTime(s, expireTime) {
    s.expiresTime = expireTime;
  },
  removeToken(s) {
    s.token = '';
  },
  reveiveIdentity(s, data) {
    s.identity = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
