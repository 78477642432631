import _ from 'lodash';
import { Api } from '../../api';

const state = {
  information: [],
};

const getters = {};

const actions = {
  fetchInformation({ commit }) {
    return Api.information.get().then(({ data: response }) => {
      commit('receiveInformation', response.data);
    });
  },
  saveInformation({ commit }, params) {
    if (params.id) {
      return Api.information.put(params.id, params).then(() => {
        commit('updateInformation', params);
      });
    }
    return Api.information.post(params).then(({ data: response }) => {
      commit('receiveNewInformation', response.data);
    });
  },
  removeInformation({ commit }, id) {
    return Api.information.delete(id).then(() => {
      commit('deleteInformation', id);
    });
  },
};

/* eslint no-param-reassign: 0, no-shadow: 0 */
const mutations = {
  receiveInformation(s, data) {
    s.information = [...data];
  },
  receiveNewInformation(s, data) {
    s.information = [...s.information, data];
  },
  updateInformation(s, data) {
    const information = _.find(s.information, { id: data.id });
    _.merge(information, data);
  },
  deleteInformation(s, id) {
    s.information = _.reject(s.information, { id });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
