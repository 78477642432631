import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/components/Layout/Layout';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "login" */ '../views/Home'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
      },
      {
        path: '/admin/:id?',
        name: 'admin',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminSave'),
      },
      {
        path: '/admin-list',
        name: 'admin-list',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminList'),
      },
      {
        path: '/districts',
        name: 'districts',
        component: () => import(/* webpackChunkName: "distric" */ '../views/Districts'),
      },
      {
        path: '/group',
        name: 'group',
        component: () => import(/* webpackChunkName: "group" */ '../views/AreaGroup'),
      },
      {
        path: '/config/:id?',
        name: 'config',
        component: () => import(/* webpackChunkName: "config" */ '../views/Config'),
      },
      {
        path: '/area-group-shipping-fee/:id?',
        name: 'area-group-shipping-fee',
        component: () => import(/* webpackChunkName: "group" */ '../views/AreaGroupShippingFee'),
      },
      // {
      //   path: '/area-group-create',
      //   name: 'area-group-create',
      //   component: () => import(/* webpackChunkName: "group" */ '../views/AreaGroupShippingFee'),
      // },
      {
        path: '/post-office/:id?',
        name: 'post-office',
        component: () => import(/* webpackChunkName: "post-office" */ '../views/postOffice/PostOfficeSave'),
      },
      {
        path: '/post-office-list',
        name: 'post-office-list',
        component: () => import(/* webpackChunkName: "post-office" */ '../views/postOffice/PostOfficeList'),
      },
      {
        path: '/parcel-models',
        name: 'parcel-models',
        component: () => import(/* webpackChunkName: "parcel" */ '../views/parcel/ParcelModels'),
      },
      {
        path: '/import-parcels',
        name: 'import-parcels',
        component: () => import(/* webpackChunkName: "parcel" */ '../views/parcel/ImportParcels'),
      },
      {
        path: '/bind-parcels',
        name: 'bind-parcels',
        component: () => import(/* webpackChunkName: "parcel" */ '../views/parcel/BindParcels'),
      },
      {
        path: '/create-parcels',
        name: 'create-parcels',
        component: () => import(/* webpackChunkName: "parcel" */ '../views/parcel/CreateParcels'),
      },
      {
        path: '/parcels',
        name: 'parcels',
        component: () => import(/* webpackChunkName: "parcel" */ '../views/parcel/Parcels'),
      },
      {
        path: '/orders/:tabName',
        name: 'orders',
        component: () => import(/* webpackChunkName: "order" */ '../views/order/OrderTabs'),
      },
      {
        path: '/order/:id',
        name: 'order-detail',
        component: () => import(/* webpackChunkName: "order" */ '../views/order/OrderDetail'),
      },
      {
        path: '/pickup-orders/:tabName',
        name: 'pickup-orders',
        component: () => import(/* webpackChunkName: "order" */ '../views/pickupOrder/OrderTabs'),
      },
      {
        path: '/pickup-order/:id',
        name: 'pickup-order-detail',
        component: () => import(/* webpackChunkName: "order" */ '../views/pickupOrder/OrderDetail'),
      },
      {
        path: '/order-prick-up/:parcel_code?',
        name: 'order-prick-up',
        component: () => import(/* webpackChunkName: "order" */ '../views/order/OrderPickup'),
      },
      {
        path: '/weighting-print-label/:parcel_code?',
        name: 'weighting-print-label',
        component: () => import(/* webpackChunkName: "order" */ '../views/order/WeightingAndPrintLabel'),
      },
      {
        path: '/order-split/:parcel_code?',
        name: 'order-split',
        component: () => import(/* webpackChunkName: "order" */ '../views/order/OrderSplit'),
      },
      {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "user" */ '../views/user/Users'),
      },
      {
        path: '/order-issues',
        name: 'order-issues',
        component: () => import(/* webpackChunkName: "order" */ '../views/order/OrderIssues'),
      },
      {
        path: '/coupons',
        name: 'coupons',
        component: () => import(/* webpackChunkName: "coupon" */ '../views/coupon/Coupon'),
      },
      {
        path: '/coupon-create',
        name: 'coupon-create',
        component: () => import(/* webpackChunkName: "coupon" */ '../views/coupon/CouponSave'),
      },
      {
        path: '/coupon-edit/:id',
        name: 'coupon-edit',
        component: () => import(/* webpackChunkName: "coupon" */ '../views/coupon/CouponSave'),
      },
      {
        path: '/coupon-detail/:id',
        name: 'coupon-detail',
        component: () => import(/* webpackChunkName: "coupon" */ '../views/coupon/CouponDetail'),
      },
      // 系統消息
      {
        path: '/system-information',
        name: 'system-information',
        component: () => import(/* webpackChunkName: "system-information" */ '../views/SystemInformation'),
      },
      {
        path: '/delivery-terms',
        name: 'delivery-terms',
        component: () => import(/* webpackChunkName: "system-information" */ '../views/Page'),
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import(/* webpackChunkName: "system-information" */ '../views/Page'),
      },
      {
        path: '/credit-card-terms',
        name: 'credit-card-terms',
        component: () => import(/* webpackChunkName: "system-information" */ '../views/Page'),
      },
      {
        path: '/user-agreement',
        name: 'user-agreement',
        component: () => import(/* webpackChunkName: "system-information" */ '../views/Page'),
      },
      {
        path: '/join-us',
        name: 'join-us',
        component: () => import(/* webpackChunkName: "join-us" */ '../views/JoinUs'),
      },
    ],
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404'),
  },
];

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    if (from.name === to.name) {
      return false;
    }
    return savedPosition || { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const { isLogined } = store.getters;
  // 已登陆
  if (isLogined && to.name === 'login') {
    next({ name: 'dashboard' });
    return;
  }
  // 未登陆
  if (!isLogined && !~['404', 'login'].indexOf(to.name)) {
    next({ name: 'login', replace: true });
    return;
  }

  if (to.meta.requireRoles) {
    if (store.getters.hasRole(to.meta.requireRoles, to.meta.requireAll || false)) {
      next();
    } else {
      next({ name: '401' });
    }
  } else {
    if (to.meta.requirePermissions) {
      if (store.getters.hasPermission(to.meta.requirePermissions, to.meta.requireAll || false)) {
        next();
      } else {
        next({ name: '401' });
      }
    }
    next();
  }

  next();
});

export default router;
